.breadcrumb {
    color: rgb(120, 119, 116);
}

.coverImage {
    display: block;
    object-fit: cover;
    width: 100%;
    max-height: 30vh;
}

.tableOfContents{
    list-style: none;
    padding-left: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.context {
    background-color: rgb(231, 243, 248);
    padding: 16px;
    border-radius: 3px;
}

.lines, li {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    line-height: 1.5 !important;
}

ul, ol {
    padding-left: 15px;
}

li {
   padding-left: 8px;
}

h2 {
    color: rgb(51, 126, 169);
}

h3 {
    color: rgb(144, 101, 176);
}

.largeEmoji {
    font-size: 1.5rem;
}

@media (min-width: 900px) {
    .left {
        padding-right: 16px;
    }

    .right {
        padding-left: 16px;
    }
}
